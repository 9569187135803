import React from "react";
import Link from "next/link";

const StartseiteMainBanner = () => {
  return (
    <>
      <div className="mainbanner">
        <div className="landingpage-banner">
          <div className="container">
            <div className="landingpage-banner-content">
              <h1
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1200"
              >
                Zukunft gestalten  
                <br />
                <span>mit Simplify Data</span>
                <br />
                Ihr Digitaler Wandel
              </h1>

              <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1200">
               Simplify Data ist Ihr Wegbereiter in eine erfolgreiche digitale Zukunft. Wir unterstützen Sie mit personalisierten Lösungen und umfassender Beratung, um die Herausforderungen der Digitalisierung zu überwinden und nachhaltiges Wachstum zu fördern.
              </p>

              <div
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="1200"
              >
                <Link href="/kontakt" className="default-btn">
                  <i className="bx bxs-hot"></i>Digital Durchstarten<span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartseiteMainBanner;
