import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";



const StartseiteFaq = () => {
  return (
    <>
      <div className="faq-area ptb-70 bg-color-f4f5fe">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="faq-accordion">
                <h2>
                  <span>Häufige Fragen</span>
                </h2>

                <Accordion allowZeroExpanded preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Kann mein Unternehmen von individuell angepassten IT-Lösungen profitieren?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Ja, wir entwickeln maßgeschneiderte IT-Lösungen, die darauf ausgerichtet sind, Ihre Geschäftsprozesse durch eine Optimierung mittels effizienter Software und Webentwicklung zu unterstützen. Unsere Lösungen sind speziell auf die Bedürfnisse kleiner und mittlerer Unternehmen (KMU) zugeschnitten.                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Wie kann mein Unternehmen durch Ihre Digital-Strategie wachsen? 
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Unsere Digital-Strategie ist darauf ausgerichtet, Ihre Marktposition durch datengesteuerte Entscheidungen und die Nutzung von KI und ML zu erweitern. Wir helfen Ihnen, innovative Lösungen zu entwickeln und Ihre digitale Transformation umzusetzen.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Was beinhaltet die App-Entwicklung?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Unsere App-Entwicklung umfasst die Konzeption, das Design und die Entwicklung von mobilen Anwendungen für iOS und Android, die nahtlos in Ihre Geschäftsprozesse integriert werden können.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Wie sieht der After-Sales-Support aus? 
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Je nach Vereinbarung umfasst unser After-Sales-Support kontinuierliche technische Betreuung und Beratung, um sicherzustellen, dass Ihre Lösung effizient läuft und mit Ihrem Unternehmen skaliert. Wir bieten regelmäßige Updates und Optimierungen, um Ihre digitale Präsenz stets aktuell zu halten.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Wie kann ich Sie für eine individuelle Beratung kontaktieren?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>ie können uns über unser Kontaktformular auf der Webseite, per E-Mail, oder telefonisch für eine individuelle Beratung erreichen. Unser Team steht Ihnen gerne zur Verfügung, um Ihre spezifischen Anforderungen zu besprechen und eine maßgeschneiderte Lösung für Sie zu entwerfen.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-5 col-md-12">
              <div className="faq-image">
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartseiteFaq;
