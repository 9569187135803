import React from "react";
import Link from "next/link";
import { useLocales } from '../../locales';

const StartseiteDienstleistung = () => {
  const { t } = useLocales();
  return (
    <>
      <div className="our-loving-clients ptb-100 bg-f8fbfa">
        <div className="container">
          <div className="section-title">
            <h2>Ihr Wegbereiter in der digitalen Zukunft</h2>
            <p>Unsere Dienstleistungen</p>
          </div>

          <div className="clients-logo-list align-items-center">
            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="100"
              data-aos-duration="1200"
            >
              <Link href="/app-development/" >
                <div className="center-service">
                <h5>App Entwicklung</h5>
             </div>
              </Link>
            </div>

            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="100"
              data-aos-duration="1200"
            >
              <Link href="software-development/" >
                <div className="center-service">
                  <h5>Software</h5>
                </div>
              </Link>
            </div>

            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <Link href="/web-development/" >
                <div className="center-service">
                 <h5>Web Development</h5>
                </div>
              </Link>
            </div>

            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-duration="1200"
            >
              <Link href="/web-development/wordpress-entwicklung/" >
                <div className="center-service">
                  <h5>Wordpress</h5>
                </div>
              </Link>
  
            </div>

            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="500"
              data-aos-duration="1200"
            >
              <Link href="/it-consulting/">
             
                <div className="center-service">
                  <h5>IT-Consulting</h5>
                </div>
              </Link>
          
            </div>

            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="600"
              data-aos-duration="1200"
            >
              <Link href="/business-analytics/" >
                <div className="center-service">
                  <h5>Business Analytics</h5>
                </div>
              </Link>
            </div>

            <div
              className="single-clients-logo"
              data-aos="fade-in"
              data-aos-delay="700"
              data-aos-duration="1200"
            >
              <Link href="/kuenstliche-intelligenz/" >
                <div className="center-service">
                  <h5>KI und ML</h5>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default StartseiteDienstleistung;
