import React from "react";
import Link from "next/link";

const StartseiteCard = () => {
  return (
    <>
      <div className="features-card-section pt-100 pb-70 bg-f8fbfa">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-3 col-sm-6 feature-card"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1200"
            >
              <div className="single-features-card blt-radius-0">
                <i className="bx bx-conversation blt-radius-0"></i>
                <h3>
                    Analyse & Beratung
                </h3>
                <p>
                  Tiefgehendes Verständnis Ihrer Ziele und Herausforderungen.
                </p>
              </div>
            </div>

          
            <div
              className="col-lg-3 col-sm-6 feature-card"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <div className="single-features-card blt-radius-0">
                <i className="bx bx-laptop blt-radius-0"></i>
                <h3>
                    Planung
                </h3>
                <p>
                  Entwerfen einer maßgeschneiderten Lösung, die Ihren Anforderungen entspricht.
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 feature-card"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1200"
            >
              <div className="single-features-card blt-radius-0">
                <i className="bx bxs-badge-check blt-radius-0"></i>
                <h3>
                    Implementierung
                </h3>
                <p>
                  Umsetzung der geplanten Lösung mit kontinuierlichem Feedback für optimale Ergebnisse.
                </p>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 feature-card"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1200"
            >
              <div className="single-features-card blt-radius-0">
                <i className="bx bxs-megaphone blt-radius-0"></i>
                <h3>
                    Support & Optimierung
                </h3>
                <p>
                  Laufende Unterstützung und Feinabstimmung, um sicherzustellen, dass die Lösung mit Ihrem Geschäft wächst.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartseiteCard;
