import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const StartseiteCaseStudies = () => {
    return (
        <section className="case-studies-area pt-100 pb-100">
            <div className="container">
                <div className="section-title text-left">
                    <span className="sub-title">Unverbindliches Erstgespräch</span>
                    <h2>Beratung nach Ihren Bedürfnissen</h2>
                </div>
            </div>

        
        <div className="container">
          <div className="service-details-content">
           <div className="service-details">
              <div className="service-details-info ">
                <div className="large-info-box">
                  <h4>Bereit, den nächsten Schritt in eine datengetriebenen Zukunft?</h4>
                  <span>Wir glauben an die Macht der Daten, um transformative Veränderungen herbeizuführen. Lassen Sie uns gemeinsam Ihre Geschäftsprozesse revolutionieren und ungenutztes Potenzial freisetzen.</span>
                </div>

                <div className="single-info-box">
                  <h4>Kontakt</h4>
                  <ul className="social">
                    <li>
                      <a href="tel://+4943170577024" target="_blank">
                        <i className='bx bxs-phone-call'></i>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@simplify-data.de" target="_blank">
                        <i className='bx bxs-envelope' ></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/simplify-data-ug/" target="_blank">
                        <i className="bx bxl-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="single-info-box">
                  <Link href="/kontakt/" className="default-btn">
                    Individuelle Beratung!
                  </Link>
                </div>
              </div>
            </div>
            </div>
          
          </div>
          
          
          
        </section>
    );
}

export default StartseiteCaseStudies;