import React from "react";
import Image from "next/image";

const StartseiteFeatures = () => {
  return (
    <>
      <div className="services-area ptb-100 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="services-image"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1200"
            >
              <div className="image">
                <Image
                  src="/images/it-consultant/Visualisierung-green.png"
                  alt="image"
                  width={1050}
                  height={567}
                />
              </div>
            </div>

            <div className="services-content it-service-content">
              <div className="content">
                <div className="features-inner-content">
                  <div className="features-item">
                    <i className="bx bx-code-alt bg-13c4a1 blt-radius-0"></i>
                    <h3>Transformation</h3>
                    <p>
                     Unsere maßgeschneiderte Beratung ermöglicht es Ihrem Unternehmen, fortschrittliche App- und Softwarelösungen nahtlos zu integrieren. 
                     Wir unterstützen Sie bei der Implementierung von Webentwicklungsprojekten, einschließlich responsiven Designs und WordPress-Plattformen, um Ihre digitale Präsenz zu maximieren.
                    </p>
                  </div>
                  <div className="features-item">
                    <i className="bx bx-cloud bg-00A87A blt-radius-0"></i>
                    <h3>IT-Lösungen für KMUs</h3>
                    <p>
                      Wir entwickeln individuell zugeschnittene IT-Lösungen, die von Cloud-Services und Sicherheitsprotokollen bis hin zu ausgereiften App-Entwicklungen und IT-Betreuungsmodellen reichen.
                      Unser IT-Consulting zielt darauf ab, Ihre Geschäftsprozesse durch effiziente Software und spezialisierte Web Development Projekte zu optimieren.
                    </p>
                  </div>
                  <div className="features-item">
                    <i className="bx bx-star bg-009185 blt-radius-0"></i>
                    <h3>Digital-Strategie</h3>
                    <p>
                      Erweitern Sie Ihre Marktposition durch datengesteuerte Entscheidungen mit unseren Business Analytics-Services und nutzen Sie die Kraft von KI und Maschinellem Lernen, um innovative Lösungen voranzutreiben.
                      Unsere Strategien beinhalten auch die Erstellung von Social Reports, um Ihre Sichtbarkeit in sozialen Netzwerken und die Interaktion mit Ihrer Zielgruppe zu stärken.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartseiteFeatures;
