import React from 'react';
import Link from "next/link";
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { useLocales } from '../../locales';

const ParallaxMainBanner = () => {
  const { t } = useLocales();
  return (
    
      <ParallaxProvider>
        <ParallaxBanner >
          <div className="landingparallaxbanner">
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_09_Back_Blue-version.png" speed={10} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_08_Tower.png" speed={-10} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_07_Cityline.png" speed={-5} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_06_Cityhall.png" speed={0} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_05_Parliament.png" speed={0} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_04_Hoern_bay.png" speed={0} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_03_Crane-2.png" speed={0} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_02_Crane.png" speed={0} />
          <ParallaxBannerLayer className='ParallaxBannerLayer' image="/images/parallax/Kiel_01_Boats.png" speed={10} />
          <div className="landingpage-banner">
            <div className="container">
              <div className="landingpage-banner-content">
                <h1
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1200"
                >
                  Zukunft gestalten
                  <br />
                  <span>mit Simplify Data</span>
                  <br />
                  Ihr Digitaler Wandel
                </h1>

                <p data-aos="fade-up" data-aos-delay="200" data-aos-duration="1200">
                Simplify Data ist Ihr Wegbereiter in eine erfolgreiche digitale Zukunft. Wir unterstützen Sie mit personalisierten Lösungen und umfassender Beratung, um die Herausforderungen der Digitalisierung zu überwinden und nachhaltiges Wachstum zu fördern.
                </p>

                <div
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-duration="1200"
                >
                  <Link href="/kontakt" className="default-btn">
                    <i className="bx bxs-hot"></i>Digital Durchstarten<span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          </div>
        </ParallaxBanner>
      </ParallaxProvider>

  );
};

export default ParallaxMainBanner;